import React, { createRef } from "react";
import cx from "classnames";
import Slide from "./Slide";
import "./index.scss";

class Slider extends React.Component {
  state = {
    activeSlideIndex: 0,
  };

  sliderRef = createRef()

  componentDidMount() {
    const intervalId = setInterval(this.handleSlider, 5000);
    this.setState({
      intervalId,
    });
    this.sliderRef.current.addEventListener("mouseover", () => {
      clearInterval(this.state.intervalId)
    })
    this.sliderRef.current.addEventListener("mouseout", () => {
      const intervalId = setInterval(this.handleSlider, 5000);
      this.setState({ intervalId })
    })
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.sliderRef.current.removeEventListener("mouseover", () => {
      clearInterval(this.state.intervalId)
    })
    this.sliderRef.current.removeEventListener("mouseout", () => {
      const intervalId = setInterval(this.handleSlider, 5000);
      this.setState({ intervalId })
    })
  }

  handleSlider = () => {
    const { activeSlideIndex } = this.state;
    const { data } = this.props;

    if (activeSlideIndex + 1 <= data.length - 1) {
      this.setState({
        activeSlideIndex: activeSlideIndex + 1,
      });
    } else {
      this.setState({
        activeSlideIndex: 0,
      });
    }
  };

  onSliderButtonClick = (index) => {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    const newIntervalId = setInterval(this.handleSlider, 5000);
    this.setState({
      activeSlideIndex: index,
      intervalId: newIntervalId,
    });
  };


  render() {
    const { activeSlideIndex } = this.state;
    const { data } = this.props;

    const translateValue = activeSlideIndex * 100;

    return (
      <div className="slider-container">
        <div
          style={{ transform: `translate(-${translateValue}%)` }}
          className="slider"
          ref={this.sliderRef}
        >
          {data.map((slide, index) => {
            return (
              <Slide
                isActive={activeSlideIndex === index}
                key={index}
                slide={slide}
              />
            );
          })}
        </div>
        <div className="slider-buttons">
          {data.map((item, index) => {
            const sliderButtonClasses = cx({
              "slider-button": true,
              "slider-button--active": index === activeSlideIndex,
            });
            return (
              <button
                onClick={() => this.onSliderButtonClick(index)}
                className={sliderButtonClasses}
                key={index}
              />
            );
          })}
        </div>
      </div >
    );
  }
}

export default Slider;
