import React from "react";
import cx from "classnames";
import "./index.scss";
import Button from "../shared/Button";
import location from "../../assets/images/icons/location.svg";
import phone from "../../assets/images/icons/phone.svg";
import mail from "../../assets/images/icons/mail.svg";
import map from "../../assets/images/aboutUs/map.png";
import { connect } from "react-redux";

// import emailjs from 'emailjs-com';


const Footer = ({ showMap, language }) => {

  // const [successVisible, setSuccessVisible] = useState(false)
  // const [errorVisible, setErrorVisible] = useState(false)

  // const successClasses = cx("success_hidden",
  //   successVisible && "success_visible"
  // )
  // const errorClasses = cx("error_hidden",
  //   errorVisible && "error_visible"
  // )

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.target);
  //   const name = data.get('name');
  //   const msg = data.get('msg');
  //   const mail = data.get('mail');
  //   const dataObject = {
  //     name: name,
  //     msg: msg,
  //     mail: mail
  //   };
  //   const serviceId = '0403991';
  //   const templateId = 'template_5ihyyvm';


  // emailjs.send('gmail', templateId, dataObject, serviceId).then(
  //   () => {
  //     setSuccessVisible(true)
  //     setTimeout(() => { setSuccessVisible(false) }, 3000)
  //   },
  //   () => {
  //     setErrorVisible(true)
  //     setTimeout(() => { setErrorVisible(false) }, 3000)
  //   }
  // );

  //   const form = document.getElementById('form');

  //   setTimeout(() => {
  //     form.reset();
  //   }, 1000);
  // };

  const footerData = [
    {
      Srpski: "Kontakt",
      English: "Contact"
    },
    {
      Srpski: "Radno vreme",
      English: "Working hours"
    },
    {
      Srpski: "Ponedeljak - Petak",
      English: "Monday - Friday"
    },
    {
      Srpski: "Subota - Nedelja",
      English: "Saturday - Sunday"
    },
    {
      Srpski: "Pišite nam",
      English: "Ask us a question"
    },
  ]

  const inputData = [
    {
      Srpski: "Vaša poruka",
      English: "Your message"
    },
    {
      Srpski: "Vaše ime",
      English: "Your name"
    },
    {
      Srpski: "Pošalji",
      English: "Send"
    }
  ]

  const footerClasses = cx({
    footer: true,
    "footer--no-map": !showMap,
  })
  return (
    <div id="footer" className={footerClasses}>
      <div className="footer-map" style={{ backgroundImage: `url(${map})` }} />
      <div className="footer-content">
        <div className="footer-contact">
          <h3 className="footer-contact-title">{footerData[0][language]}</h3>
          <div>
            <div className="footer-contact__phones">
              <img src={phone} alt="phone" />
              <div>
                <a href="+381 11 8701493">+381 11 870 14 93</a>
                <a href="+381 11 8701491">+381 11 870 14 91</a>
              </div>
            </div>
            <div className="footer-location">
              <img src={location} alt="location" />
              <p>Železnička 42/D, Barič 11504, Srbija</p>
            </div>
            <div className="footer-mail">
              <img src={mail} alt="e-mail" />
              <a href="mailto:transport@dragicevic.rs">
                transport@dragicevic.rs
              </a>
            </div>
          </div>
        </div>
        <div className="footer-workingHours">
          <h3>{footerData[1][language]}</h3>
          <div className="footer-working-hours">
            <div>
              <p>{footerData[2][language]}</p>
              <p>07:00 - 22:00h</p>
            </div>
            <div>
              <p>{footerData[3][language]}</p>
              <p>07:00 - 17:00h</p>
            </div>
          </div>
        </div>
        <div className="footer-contactForm">
          <h3>{footerData[4][language]}</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleSubmit()
            }}
          >
            <textarea required={true} id="msg" name="msg" className="footer-input" placeholder={inputData[0][language]} />
            <input required={true} id="name" name="name" className="footer-input" placeholder={inputData[1][language]} />
            <input required={true} id="mail" name="mail" className="footer-input" placeholder="E-mail" />
            <div className="footer-form-button-wrapper">
              <Button type="submit" isWhite label={inputData[2]} />
            </div>
            {/* <img className={successClasses} src="" alt="success" />
            <img className={errorClasses} src="" alt="error" /> */}
          </form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Footer);
