import * as React from "react";
import "./index.scss";
import cx from "classnames";
import { routeCodes } from "../../../config/routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../shared/Button";

function ServiceBox({ data, isActive, history, language }) {
  const boxClasses = cx({
    serviceBox: true,
    serviceBox__active: isActive,
  });

  const handleButtonClick = (section) => {
    history.push({
      pathname: routeCodes.SERVICES,
      state: {
        scrollTo: section,
      },
    });
  };

  const label = {
    Srpski: "Saznaj više",
    English: "More"
  }

  return (
    <div className={boxClasses}>
      <img className="serviceBox-image" src={data.image} alt={data.title[language]} />
      <div className="serviceBox-content">
        <div className="serviceBox-content-inner">
          <h4>{data.title[language]}</h4>
          <p
            className="serviceBox-content-desc"
            dangerouslySetInnerHTML={{ __html: data.description[language] }}
          />
        </div>
        <Button
          label={label}
          onClick={() => handleButtonClick(data.title)}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default withRouter(connect(mapStateToProps)(ServiceBox));
