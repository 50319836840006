import React, { useState, useEffect, useRef } from "react";
import Routes from "./config/routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./scss/base/app.scss";
import MobileNav from "./components/Header/MobileNav";
import up from "./assets/images/icons/up.svg";
import { connect } from "react-redux";

import { WindowManager } from "react-window-decorators";
import animateScrollTo from 'animated-scroll-to';

const BREAKPOINTS = [
  {
    name: "sm",
    media: "(min-width: 0)",
  },
  {
    name: "md",
    media: "(min-width: 768px)",
  },
  {
    name: "lg",
    media: "(min-width: 1024px)",
  },
  {
    name: "xl",
    media: "(min-width: 1280px)",
  },
  {
    name: "xxl",
    media: "(min-width: 1440px)",
  },
  {
    name: "xxxl",
    media: "(min-width: 1800px)",
  },
];

new WindowManager(BREAKPOINTS);

function App() {
  const [burgerActive, setBurgerActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const introRef = useRef(null)

  const src = "https://www.youtube.com/embed/BS9tgfkQA8o?modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1"

  const iframe = document.getElementById("iframe");
  const handleLoad = () => {
    if (introRef) {
      setTimeout(() => {
        introRef.current.classList.remove('visible')
      }, 8000);
    }
  }


  const renderIntro = () => {
    return (
      <div ref={introRef} className="intro visible">
        <iframe id="iframe" title="intro" frameBorder="0" allow='autoplay'
          src={src}></iframe>
      </div >
    )
  }

  useEffect(() => {
    if (introRef.current) {
      handleLoad()
    }
  }, [])

  useEffect(() => {
    if (burgerActive) {
      document.querySelector("html").classList.add("noScrollable");
      document.querySelector("body").classList.add("noScrollable");
    } else {
      document.querySelector("html").classList.remove("noScrollable");
      document.querySelector("body").classList.remove("noScrollable");
    }
  }, [burgerActive]);

  const onScroll = () => {
    const scrollPos = window.pageYOffset
    setScrollPosition(scrollPos)
  }
  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    iframe && iframe.addEventListener('load', handleLoad, true)
    return () => {
      document.removeEventListener('scroll', onScroll)
      iframe && iframe.removeEventListener('load', handleLoad, false)
    }
  })

  const windowSize = window.innerWidth


  return (
    <>
      {windowSize > 1024 && renderIntro()}
      <div className="App">
        <MobileNav
          setBurgerActive={setBurgerActive}
          burgerActive={burgerActive}
        />
        <Header burgerActive={burgerActive} setBurgerActive={setBurgerActive} />
        <Routes />
        <Footer showMap={false} />
        <div style={{ display: scrollPosition > 500 ? 'flex' : 'none' }} onClick={() => animateScrollTo(0)} className="back-to-top">
          <img src={up} alt="up" />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default connect(mapStateToProps)(App);

