import * as React from "react";
import cx from "classnames";
import "./index.scss";
import { connect } from "react-redux";

function Button({ onClick, label, isWhite, language }) {
  const buttonClasses = cx({
    button: true,
    button__white: isWhite,
  });

  return (
    <button className={buttonClasses} onClick={onClick}>
      <p>{label[language]}</p>
    </button>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Button);
