import React from "react";
import { withScroll } from "react-window-decorators";

import { connect } from "react-redux";

import "./index.scss";

class Counter extends React.Component {
  counterRef = React.createRef();

  state = {
    shouldStartCounting: false,
    countNumbersToShow: [],
  };

  componentDidUpdate(prevProps) {
    const { shouldStartCounting } = this.state;
    const { scrollPosition } = this.props;
    if (prevProps.scrollPosition !== scrollPosition && !shouldStartCounting) {
      const WINDOW_HEIGHT = window.innerHeight;
      const counterOffsetTop = this.counterRef.current.offsetTop;
      const OFFSET = 100;

      if (scrollPosition - OFFSET >= counterOffsetTop - WINDOW_HEIGHT) {
        this.count();
      }
    }
  }

  count = () => {
    this.setState({
      shouldStartCounting: true,
    });
  };

  render() {
    const { data, language } = this.props;
    const { shouldStartCounting } = this.state;
    return (
      <div className="statisticsCounter" ref={this.counterRef}>
        {data.map((stats, index) => {
          return (
            <div key={index} className="counter--item">
              <CounterNumber
                shouldStartCounting={shouldStartCounting}
                data={stats}
              />
              <div className="divider" />
              <p className="text">{stats.text[language]}</p>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default withScroll(connect(mapStateToProps)(Counter));

class CounterNumber extends React.Component {
  state = {
    numberToShow: 0,
  };

  componentDidUpdate(prevProps) {
    const { shouldStartCounting } = this.props;
    if (prevProps.shouldStartCounting !== shouldStartCounting) {
      this.count();
    }
  }

  count = () => {
    const {
      data: { number },
    } = this.props;
    let num = 0;

    const intervalDuration = 650;
    const step = Math.floor(intervalDuration / number);

    const timer = setInterval(() => {
      num = num + 1;
      this.setState({
        numberToShow: num,
      });
      if (num === number) {
        clearInterval(timer);
      }
    }, step);
  };

  render() {
    const { data } = this.props;
    const { numberToShow } = this.state;
    return (
      <p className="number">
        {numberToShow}
        {data.type && <span dangerouslySetInnerHTML={{ __html: data.type }} />}
      </p>
    );
  }
}
