import React from "react";
import "./index.scss";
import { NavLink } from 'react-router-dom'
import logo from "../../../assets/images/logos/logo.png";

import cx from "classnames"
import { routeCodes } from '../../../config/routes'

const MobileNav = ({ burgerActive, setBurgerActive }) => {

  const mobileNavClasses = cx(
    "mobile-nav",
    burgerActive && "mobile-nav__active"
  );
  return (
    <div className={mobileNavClasses}>
      <img className="logo" src={logo} alt="logo" />
      <div className="links">
        <NavLink className="link" activeClassName="link__active" onClick={() => setBurgerActive(!burgerActive)} exact to={routeCodes.HOME}>POČETNA</NavLink>
        <NavLink className="link" activeClassName="link__active" onClick={() => setBurgerActive(!burgerActive)} exact to={routeCodes.ABOUT}>O NAMA</NavLink>
        <NavLink className="link" activeClassName="link__active" onClick={() => setBurgerActive(!burgerActive)} exact to={routeCodes.SERVICES}>USLUGE</NavLink>
        <NavLink className="link" activeClassName="link__active" onClick={() => setBurgerActive(!burgerActive)} exact to={routeCodes.GALLERY}>GALERIJA</NavLink>
        <NavLink className="link" activeClassName="link__active" onClick={() => setBurgerActive(!burgerActive)} exact to={routeCodes.CONTACT}>KONTAKT</NavLink>
      </div>
    </div>
  )
}

export default MobileNav