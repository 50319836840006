import React from "react";
import "./index.scss";
import Plx from 'react-plx';

import { connect } from "react-redux";

const Icons = ({ data, language }) => {

  const iconsParallax = [
    {
      start: 'self',
      duration: 300,
      easing: [0.1, 0.375, 0.865, 1],
      properties: [
        {
          startValue: 200,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ]

  return (
    <Plx parallaxData={iconsParallax}>
      <div className="icons">
        {data.map((icon, index) => {
          return (
            <div key={index} className="icon">
              <img className="icon--image" src={icon.icon} alt="icon" />
              <div className="divider" />
              <p className="icon--text">{icon.text[language]}</p>
            </div>
          )
        })}
      </div>
    </Plx>
  )
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Icons);