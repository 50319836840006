import * as React from "react";
import "./index.scss";
import Services from "../../components/home/Services";
import data from "../../config/data";
import Goals from "../../components/home/Goals";
import Slider from "../../components/shared/Slider";
import Partners from '../../components/home/Partners';
import ad from '../../assets/images/smc.jpg';

import { connect } from "react-redux"

const HomeScreen = ({ language }) => {

  const title = {
    Srpski: "Ovlašćeni servis",
    English: "Authorized service"
  }

  return (
    <div className="homeScreen">
      <Slider data={data.slides} />
      <Services data={data.services} />
      <Goals data={data.goals} />
      <div className="homeScreen-exclusive">
        <img src={ad} alt="service" />
        <h3>{title[language]}</h3>
      </div>
      <Partners data={data.partners} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(HomeScreen);
