import React, { useState } from "react";
import "./index.scss";

import next from "../../../assets/images/icons/right.svg";
import prev from "../../../assets/images/icons/left.svg";

const GallerySlider = ({ slides }) => {
  const [activeImage, setActiveImage] = useState(0)


  const handleSwitchImages = (direction) => {
    if (direction === "next") {
      setActiveImage(
        activeImage === slides.length - 1 ? 0 : activeImage + 1
      );
    } else {
      setActiveImage(
        activeImage === 0 ? slides.length - 1 : activeImage - 1
      );
    }
  }

  const translateValue = activeImage * 100;

  return (
    <div className="gallery--slider--container">
      <div style={{ transform: `translateX(${-translateValue}%)` }} className="gallery--slider">
        {slides.map((slide, index) => {
          return (
            <div key={index} style={{ backgroundImage: `url(${slide})` }} className="gallery--slide" />
          )
        })}
      </div>
      <div className="arrow--next">
        <img onClick={() => handleSwitchImages("next")} src={next} alt="next" />
      </div>
      <div className="arrow--prev">
        <img onClick={() => handleSwitchImages("prev")} src={prev} alt="prev" />
      </div>
    </div>
  )
}

export default GallerySlider;
