import React from 'react'
import './index.scss'
import Plx from 'react-plx'

import { connect } from "react-redux";

const Partners = ({ data, language }) => {

  const parallaxData = [
    {
      start: 'self',
      duration: 600,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity'
        }
      ]
    }
  ]
  return (
    <div style={{ backgroundImage: `url(${data.img})` }} className="partners">
      <div className="partners-content">
        <h5>{data.title[language]}</h5>
        <Plx parallaxData={parallaxData}>
          <div className="partners-logos">
            {data.logos.map((logo, index) => {
              return (
                <img key={index} src={logo} alt="logo" />
              )
            })}
          </div>
        </Plx>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Partners)