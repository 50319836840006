import React from "react";
import cx from "classnames";
import "./index.scss";
import Button from "../../Button";
import { NavLink, withRouter } from "react-router-dom"
import { routeCodes } from "../../../../config/routes";
import { connect } from "react-redux";

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isContentVisible: props.isActive,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({
          isContentVisible: this.props.isActive,
        });
      }, 1200);
    }
  }

  handleButtonClick = (section) => {
    this.props.history.push({
      pathname: routeCodes.SERVICES
    });
  };

  render() {
    const { slide, language } = this.props;
    const { isContentVisible } = this.state;
    const slideClasses = cx({
      slide: true,
      slide__active: isContentVisible,
    });

    return (
      <div
        style={{ backgroundImage: `url(${slide.image})` }}
        className={slideClasses}
      >
        <div className="slide--overlay" />
        <div className="slide-content">
          <h1>{slide.title[language]}</h1>
          <p className="slide-content-desc">{slide.description[language]}</p>
          <NavLink to={slide.path}>
            <Button isWhite label={slide.cta} onClick={() => { }} />
          </NavLink>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default withRouter(connect(mapStateToProps)(Slide));
