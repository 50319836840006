import * as React from "react";
import { Route, Switch } from "react-router-dom";

import HomeScreen from "../screens/home";
import AboutScreen from "../screens/aboutUs";
import ServicesScreen from "../screens/services";
import GalleryScreen from "../screens/gallery";
import ContactScreen from "../screens/contact";
import ScrollToTop from "../components/scrollToTop";


const publicPath = "/";

export const routeCodes = {
  HOME: publicPath,
  ABOUT: "/o-nama",
  SERVICES: "/naše-usluge",
  GALLERY: "/galerija",
  CONTACT: "/kontakt"
};

export default () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path={routeCodes.HOME} render={() => <HomeScreen />} />
        <Route exact path={routeCodes.ABOUT} render={() => <AboutScreen />} />
        <Route exact path={routeCodes.SERVICES} render={() => <ServicesScreen />} />
        <Route exact path={routeCodes.GALLERY} render={() => <GalleryScreen />} />
        <Route exact path={routeCodes.CONTACT} render={() => <ContactScreen />} />
      </Switch>
    </ScrollToTop>
  );
};
