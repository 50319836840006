import React from "react";
import "./index.scss";
import phoneGraphic from "../../assets/images/logos/phoneGraphic.png";
import map from "../../assets/images/aboutUs/map.png";

import { connect } from "react-redux";

const Contact = ({ language }) => {
  const contactInfo = [
    {
      Srpski: "Kontaktirajte nas",
      English: "Contact us",
    },
    {
      Srpski: "Centrala",
      English: "Base",
    },
    {
      Srpski: "Generalni direktor",
      English: "CEO (Chief executive officer)",
    },
    {
      Srpski: "Izvršni direktor",
      English: "CFO (Chief financial officer)",
    },
    {
      Srpski: "Carinski magacin",
      English: "Customs warehouse",
    },
    {
      Srpski: "Špedicija",
      English: "Freight company",
    },
    {
      Srpski: "Transport",
      English: "Transportation",
    },
    {
      Srpski: "Servis",
      English: "Service center",
    },
    {
      Srpski: "Tehnički pregled i registracija vozila",
      English: "Service center",
    },
  ];
  return (
    <div className="contact">
      <div className="contact-content">
        <div
          style={{ backgroundImage: `url(${map})` }}
          className="contact-map"
        />
        <div className="contact-box">
          <img className="contact-box-icon" alt="phone" src={phoneGraphic} />
          <div className="divider" />
          <h1 className="contact-box-title">{contactInfo[0][language]}</h1>
          <p>Dragićević d.o.o.</p>
          <div className="contact-items">
            <div className="contact-items-wrapper">
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[1][language]}
                </h3>
                <p>Železnicka 42/d. 11504 Baric</p>
                <a href="tel:+381 11 8701 493">Tel: +381 11 8701 493</a>
                <a href="mailto:transport@dragicevic.rs">
                  E-mail: transport@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[2][language]}
                </h3>
                <p>Dragicevic Slobodan</p>
                <a href="tel:+381 11 8701 493">Tel: +381 11 8701 493</a>
                <a href="mailto:slobodan@dragicevic.rs">
                  E-mail: slobodan@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[3][language]}
                </h3>
                <p>Nikola Bojanović</p>
                <div className="contact-mobile-wrapper">
                  <a href="tel:063/280-621">Tel: 063/280-621</a>
                </div>
                <a href="mailto:nikolab@dragicevic.rs">
                  E-mail: nikolab@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[4][language]}
                </h3>
                <p>Železnicka 42/d. 11504 Baric</p>
                <a href="mailto:magacin@dragicevic.rs">
                  E-mail: magacin@dragicevic.rs
                </a>
                <p>Nikola Dragićević</p>
                <a href="tel:063/1085-085">Tel: 063/1085-085</a>
              </div>
            </div>
            <div className="contact-items-wrapper">
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[5][language]}
                </h3>
                <p>Ognjen Vukosavljević</p>
                <a href="tel:065/875-70-64">Tel: 065/875-70-64</a>
                <br />
                <a href="mailto:spedicija@dragicevic.rs">
                  E-mail: spedicija@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[6][language]}
                </h3>
                <p>Lazar Vukosavljević</p>
                <a href="tel:063/233-238">Tel: 063/233-238</a>
                <br />
                <p>Ivan Aleksić</p>
                <a href="tel:+381 63 328 337">Tel: +381 63 328 337</a>
                <br />
                <p>Dalibor Martać</p>
                <a href="tel:063/281-900">Tel: 063/281-900</a>
                <a href="mailto:spedicija@dragicevic.rs">
                  E-mail: transport@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[7][language]}
                </h3>
                <a href="tel:063/239-418">Tel: 063/239-418</a>
                <br />
                <p>Železnicka 42/d. 11504 Baric</p>
                <a href="mailto:servis@dragicevic.rs">
                  E-mail: servis@dragicevic.rs
                </a>
              </div>
              <div className="contact-item">
                <h3 className="contact-item-title">
                  {contactInfo[8][language]}
                </h3>
                <a href="tel:063/280-355">Tel: 063/280-355</a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default connect(mapStateToProps)(Contact);
