import * as React from "react";
import "./index.scss";
import Plx from 'react-plx';

import { connect } from "react-redux";

function Goals({ data, language }) {

  const parallaxData = [
    {
      start: '.goals-text',
      duration: 700,
      easing: [0.25, 0.1, 0.53, 1],
      properties: [
        {
          startValue: -300,
          endValue: 0,
          property: 'translateX'
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity'
        }
      ]
    }
  ]

  const colorAnimation = [
    {
      start: "self",
      duration: 600,
      easing: "easeInOut",
      properties: [
        {
          startValue: "#1e2494",
          endValue: "rgba(19, 23, 94, 1)",
          property: "backgroundColor"
        }
      ]
    }
  ]

  return (
    <div className="goals">
      <Plx className="goals-wrapper" parallaxData={colorAnimation}>
        <div className="goals-text">
          <Plx parallaxData={parallaxData}>
            <p>{data.text[language]}</p>
            <p className="goals-text-white">{data.whiteText[language]}</p>
          </Plx>
        </div>
        <img className="goals-image" src={data.image} alt="goals" />
      </Plx>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Goals);
