import * as React from "react";
import "./index.scss";
import { withWindow } from "react-window-decorators";

import ServiceBox from "../ServiceBox";
import Plx from "react-plx";

import { connect } from "react-redux";

class Services extends React.Component {
  state = {
    activeSlideIndex: 0,
  };

  handleTouchStart(event) {
    this.x = event.touches[0].clientX;
    this.y = event.touches[0].clientY;
  }

  handleTouchMove(event) {
    this.xM = event.changedTouches[0].clientX;
    this.yM = event.changedTouches[0].clientY;
    const xDiff = this.xM - this.x;
    const yDiff = this.yM - this.y;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.swipe("prev");
      } else {
        this.swipe("next");
      }
    }
    this.x = null;
    this.y = null;
  }

  swipe = (direction) => {
    const { activeSlideIndex } = this.state;
    const { data } = this.props;
    const MAX_SWIPE = data.boxes.length - 1;
    if (direction === "next" && activeSlideIndex < MAX_SWIPE) {
      this.setState({
        activeSlideIndex: activeSlideIndex + 1,
      });
    } else if (direction === "prev" && activeSlideIndex !== 0) {
      this.setState({
        activeSlideIndex: activeSlideIndex - 1,
      });
    }
  };

  render() {
    const { data, breakpoint, language } = this.props;
    const { activeSlideIndex } = this.state;

    const initialMarginOffset = 15;

    const remIncrement =
      activeSlideIndex === 1
        ? initialMarginOffset
        : initialMarginOffset + 10 * (activeSlideIndex - 1);

    const isSmall = breakpoint === "sm";

    const transform = isSmall
      ? activeSlideIndex === 0
        ? "translateX(4rem)"
        : `translateX(calc(${-100 * activeSlideIndex}% + ${remIncrement}rem))`
      : "none";

    const parallaxData = [
      {
        start: "self",
        duration: 450,
        easing: [0.25, 0.1, 0.53, 1],
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "opacity",
          },
        ],
      },
    ];

    const boxParallax = [
      {
        start: "self",
        duration: 700,
        easing: [0.25, 0.1, 0.53, 1],
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "opacity",
          },
        ],
      },
    ];

    const servicesData = [
      {
        Srpski: "Naše usluge",
        English: "Our services",
      },
      {
        Srpski: `Od osnivanja firme se najviše obraca pažnja na transport kao na
              jednu od glavnih delatnosti preduzeca. Naš vozni park se stalno
            modernizuje i proširuje, a u ovom trenutku se sastoji od
              <b>60 najsavremenijih SCANIA kamiona</b> sa EURO-5 i EURO-6
            motorima. Sva vozila poseduju navigaciju i pracenje u tehnologiji
            GPRS-a. Sva naša vozila su pokrivena CMR osiguranjem, što obezbeđuje
            siguran transport robe od mesta utovara do mesta istovara.`,
        English: `Since its establishment, transport has been one of the main activities of the company. Our vehicle fleet is constantly being modernized and expanded, and currently it consists of <b>40 state-of-the-art SCANIA trucks</b> with EURO-5 and EURO-6 engines. All vehicles are equipped with GPS navigation and tracking technology. All our vehicles are covered by CMR insurance, which ensures safe transport of goods from the place of loading to the place of unloading.`,
      },
    ];

    return (
      <div
        style={{ backgroundImage: `url(${data.mainImg})` }}
        className="services"
      >
        <div className="services-overlay" />
        <div className="services-content">
          <Plx parallaxData={window.innerWidth > 768 ? parallaxData : []}>
            <h3 className="services-title">{servicesData[0][language]}</h3>
            <p
              className="services-description"
              dangerouslySetInnerHTML={{ __html: servicesData[1][language] }}
            />
          </Plx>
          <Plx parallaxData={boxParallax}>
            <div
              className="services-boxes"
              onTouchStart={(event) => this.handleTouchStart(event)}
              onTouchMove={(event) => this.handleTouchMove(event)}
              style={{
                transform,
              }}
            >
              {data.boxes.map((item, index) => (
                <ServiceBox
                  isActive={activeSlideIndex === index}
                  key={index}
                  data={item}
                />
              ))}
            </div>
          </Plx>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default withWindow(connect(mapStateToProps)(Services));
