import React from "react";
import "./index.scss";

import data from "../../config/data";
import Counter from "../../components/aboutUs/Counter";
import Icons from "../../components/aboutUs/Icons";

import map from "../../assets/images/aboutUs/map.png";
import Plx from "react-plx";

import { connect } from "react-redux";

const AboutUs = ({ language }) => {
  const aboutUs = data.aboutUs;
  const stats = data.aboutUs.stats;
  const icons = data.aboutUs.icons;

  const parallaxData = [
    {
      start: "self",
      duration: 600,
      easing: [0.1, 0.5, 0.65, 1],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const goalsData = [
    {
      start: "self",
      duration: 600,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const aboutData = [
    {
      Srpski: "O nama",
      English: "About us",
    },
    {
      Srpski: `Preduzeće Dragićević iz Bariča je osnovano 1992. godine sa ciljem da pruža što kvalitetnije usluge prevoza robe u međunarodnom
            transportu.Stalnim praćenjem novih standarda i tehnologija, veoma kvalitetnom i brzom uslugom, te kvalitetnim i stručnim timom,
            danas posluje kao malo preduzeće sa<b>60 vozila i 90 zaposlenih</b> i predstavlja pouzdanog, ekspeditivnog, iskusnog
            i ozbiljnog partnera na koga se može računati u svakom trenutku.`,
      English: `The Dragicevic company was founded in 1992 in the small city of Baric.  Providing the highest quality transportation services on an international level was our dream from the very start, and it became our everyday standard. 
            Staying technologically relevant we are constantly coming up to the highest standards.With premium expeditious service, and a team of devoted professionals we are proud to operate as a small company with <b>60 vehicles and 90 employees</b>.We are a reliable, experienced and serious partner you can always rely on.`,
    },
    {
      Srpski: "Vizija i ciljevi preduzeća",
      English: "Our goals and visions",
    },
    {
      Srpski: `Sebi smo postavili jasne ciljeve koje do sada ispunjavamo postepeno,
            polako i sigurno, a to su:
              <b>korektnost i sigurnost u poslovanju</b>, finansijska stabilnost
            preduzeća, učvršćivanje već postojećih poslovnih veza, otvaranje
            saradnje sa novim klijentima, povećanje voznog parka i broja
            zaposlenih. Zato težimo da budemo stalno u pokretu, da pomeramo
            granice i pravimo nove korake, i da se konstatno upuštamo u nove
            avanture, ali pre svega da uvek budemo na otvorenom drumu i
            konstantno dostupni našim komitentima.
            <br />
              <br />
            Dragićević doo na veoma velikom i razvijenom tržistu važi za
            pouzdanog, ekspeditivnog, iskunog i ozbiljnog partnera, na koga se
            može računati u svakom trenutku. Mnogobrojni partneri sa kojima
            poslujemo godinama, su naša najveća preporuka. Veličina, mobilnost,
            savremenost i organizovanost našeg voznog parka i usluga pruža
            potpuno poverenje stalnim i budućim komitentima.
            <br />
              <br />
            Moto i osnovni cilj kompanije Dragićević je zadovoljan klijent. Mi
            poslujemo profesionalno, kvalitetno, ekonomično i u skladu sa
            najvišim svetskim standardima.`,
      English: `We have set ourselves clear goals that we are fulfilling gradually, slowly and safely, which are: <b>trust and safety in the business</b>, financial stability of the company, building our partnership stronger by every day, staying open for new clients and increasing our vehicles and employees number.
            That is why we strive to be constantly on the move, to push the boundaries and take new steps, and to constantly embark on new adventures, but above all to always be on the open road and constantly available to our clients.<br /> <br />
            In a very large and developed market, Dragićević doo is considered a reliable, expeditious, experienced and serious partner, who can be counted on at any time. Our numerous long term partners are our biggest and the best recommendation. The size, mobility, modernity and organization of our fleet and services provide complete trust to regular and future customers.<br /> <br />
            Our MOTTO and GOAL is a happy and satisfied Client. We operate professionally, with quality, economically and in accordance with the highest world standards.`,
    },
  ];

  return (
    <div className="aboutUs">
      <div
        style={{ backgroundImage: `url(${aboutUs.mainImg})` }}
        className="aboutUs--hero"
      />
      <div className="aboutUs--text">
        <Plx parallaxData={window.innerWidth > 768 ? parallaxData : []}>
          <h3 className="services-title">{aboutData[0][language]}</h3>
          <p
            className="services-description"
            dangerouslySetInnerHTML={{ __html: aboutData[1][language] }}
          />
        </Plx>
      </div>
      <Counter data={stats} />
      <div
        style={{ backgroundImage: `url(${map})` }}
        className="aboutUs--map"
      />
      <div
        style={{ backgroundImage: `url(${data.services.mainImg})` }}
        className="ourGoals"
      >
        <div className="services-overlay" />
        <div className="aboutUs--text">
          <Plx parallaxData={goalsData}>
            <h3 className="services-title">{aboutData[2][language]}</h3>
            <p
              className="services-description"
              dangerouslySetInnerHTML={{ __html: aboutData[3][language] }}
            />
          </Plx>
        </div>
        <Icons data={icons} />
      </div>
      <div
        style={{ backgroundImage: `url(${aboutUs.lowerImg})` }}
        className="aboutUs--hero__lower"
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default connect(mapStateToProps)(AboutUs);
