import React from "react";
import "./index.scss";
import data from "../../config/data";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class ServicesScreen extends React.Component {
  elementRef = React.createRef();

  componentDidMount() {
    const { location } = this.props;

    if (location.state) {
      window.scrollTo(0, this.elementRef.current.offsetTop - 40);
    }
  }

  render() {
    const { location, language } = this.props;

    const title = {
      Srpski: "Naše usluge",
      English: "Our services"
    }
    return (
      <div
        className="services-screen"
        style={{ backgroundImage: `url(${data.services.mainImg})` }}
      >
        <div className="services-overlay" />
        <div className="services-screen-content">
          <h1 className="services-screen-title">{title[language]}</h1>
          <p
            className="services-screen-description"
            dangerouslySetInnerHTML={{ __html: data.services.title[language] }}
          />
          {data.services.boxes.map((service, index) => {
            const shouldAddRef = location.state
              ? location.state.scrollTo === service.title
              : false;

            return (
              <div
                className="services-screen-item"
                ref={shouldAddRef ? this.elementRef : null}
                key={index}
              >
                <img
                  className="services-screen-item-image"
                  src={service.image}
                  alt={service.title[language]}
                />
                <div className="services-screen-item-content">
                  <p className="services-screen-item-title">{service.title[language]}</p>
                  <div className="divider" />
                  <p
                    className="services-screen-item-description"
                    dangerouslySetInnerHTML={{
                      __html: service.pageDescription[language],
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default withRouter(connect(mapStateToProps)(ServicesScreen));
