import React from "react";
import "./index.scss";
import GallerySlider from "../../components/gallery/GallerySlider";
import data from "../../config/data";

const Gallery = () => {


  const slides = data.gallery
  return (
    <div className="gallery">
      <GallerySlider slides={slides} />
    </div>
  )
}

export default Gallery;