import transport from "../assets/images/transport.jpg";
import spedicija from "../assets/images/spedicija.jpg";
import carinskimagazin from "../assets/images/carinskimagazin.jpg";
import servis from "../assets/images/servis.jpg";
import parking from "../assets/images/parking.jpg";
import perionica from "../assets/images/perionica.jpg";
import goal from "../assets/images/goal.jpg";
import naslovna from "../assets/images/naslovna.jpg";
import mapa from "../assets/images/mapa.jpg";
import partners from "../assets/images/partners.jpg";

import ads from "../assets/images/logos/ads.png";
import cdg from "../assets/images/logos/cdg.png";
import dbs from "../assets/images/logos/dbs.png";
import dhlc from "../assets/images/logos/dhlc.png";
import dunav from "../assets/images/logos/dunav.png";
import fercam from "../assets/images/logos/fercam.png";
import gwc from "../assets/images/logos/gwc.png";
import knc from "../assets/images/logos/knc.png";
import millagrisrl from "../assets/images/logos/millagrisrl.png";
import mol from "../assets/images/logos/mol.png";
import scania from "../assets/images/logos/scania.png";
import smc from "../assets/images/logos/smc.png";

import truckGraphic from "../assets/images/logos/truckGraphic.png";
import listGraphic from "../assets/images/logos/listGraphic.png";
import phoneGraphic from "../assets/images/logos/phoneGraphic.png";
import aboutLower from "../assets/images/aboutUs/aboutLower.jpg";

import img1 from "../assets/images/gallery/img1.jpg";
import img2 from "../assets/images/gallery/img2.jpg";
import img3 from "../assets/images/gallery/img3.jpg";
import img4 from "../assets/images/gallery/img4.jpg";
import img5 from "../assets/images/gallery/img5.jpg";
import img6 from "../assets/images/gallery/img6.jpg";
import img7 from "../assets/images/gallery/img7.jpg";
import img8 from "../assets/images/gallery/img8.jpg";
import img9 from "../assets/images/gallery/img9.jpg";
import img10 from "../assets/images/gallery/img10.jpg";
import img11 from "../assets/images/gallery/img11.jpg";
import img13 from "../assets/images/gallery/img13.jpg";
import img14 from "../assets/images/gallery/img14.JPG";
import img15 from "../assets/images/gallery/img15.JPG";
import img16 from "../assets/images/gallery/img16.jpg";

import main from "../assets/images/aboutUs/main.JPG";

const data = {
  slides: [
    {
      image: naslovna,
      title: {
        Srpski: "Jaka distributivna mreža",
        English: "Strong distribution network",
      },
      description: {
        Srpski:
          "Na transport se od osnivanja obraća pažnja kao na jednu od glavnih delatnosti preduzeća. Naš vozni park se stalno modernizuje i proširuje, a u ovom trenutku se sastoji od 60 najsavremenijih SCANIA kamiona sa EURO-5 i EURO-6 motorima.",
        English:
          "Since its establishment, transport has been one of the main activities of the company. Our vehicle fleet is constantly being modernized and expanded, and currently it consists of 60 state-of-the-art SCANIA trucks with EURO-5 and EURO-6 engines.",
      },
      cta: {
        Srpski: "saznaj više",
        English: "more",
      },
      path: "/o-nama",
    },
    {
      image: goal,
      title: {
        Srpski: "Transport",
        English: "Transportation",
      },
      description: {
        Srpski: `Sektor transporta i logistike vrši organizaciju prevoza pošiljki drumskim transportom. Težište poslovanja je na drumskom prevozu širom Evrope koji organizujemo vozilima iz sopstvenog voznog parka.`,
        English:
          "The Transportation and Logistics Sector coordinates the transport of consignments by road. Our main business focus is on road transport throughout Europe, with vehicles from our own fleet.",
      },
      cta: {
        Srpski: "saznaj više",
        English: "more",
      },
      path: "/naše-usluge",
    },
    {
      image: parking,
      title: {
        Srpski: "Špedicija",
        English: "Freight company",
      },
      description: {
        Srpski:
          "U cilju kompletiranja poslovne ponude u oblasti transporta robe, pružamo špediterske usluge zainteresovanim korisnicima, kao smeštanje robe u carinski magacin.",
        English:
          "In order to complete the business offer in the field of goods transport, we provide freight forwarding services to interested customers, such as placing goods in a customs warehouse.",
      },
      cta: {
        Srpski: "saznaj više",
        English: "more",
      },
      path: "/naše-usluge",
    },
    {
      image: perionica,
      title: {
        Srpski: "Perionica",
        English: "Truck wash",
      },
      description: {
        Srpski:
          "U okviru servisa posedujemo i automatsku perionicu. Nova automatska perionica marke Kärcher je najsavremenije generacije koja pruža mogućnost podnog pranja pod pritiskom od 60 bara, intenzivno predpranje, pranje sa tri četke, visokopritisno pranje spinerima (60 bara) i voskiranje.",
        English:
          "One of the services we offer is fully automated truck wash. The innovative and high-performance Kärcher wash system is the state-of-the-art generation with  60 bar floor washing, intensive pre-washing, three-brush washing, high-pressure spinner washing (60 bar) and waxing.",
      },
      cta: {
        Srpski: "saznaj više",
        English: "more",
      },
      path: "/naše-usluge",
    },
  ],
  services: {
    mainImg: mapa,
    title: {
      Srpski: `Od osnivanja firme se najviše obraća pažnja na transport kao na jednu od glavnih delatnosti preduzeća. Naš vozni park se stalno
    modernizuje i proširuje, a u ovom trenutku se sastoji od <b>60 najsavremenijih SCANIA kamiona</b> sa EURO-5 i EURO-6 motorima. Sva
    vozila poseduju navigaciju i praćenje u tehnologiji GPRS-a. Sva naša vozila su pokrivena CMR osiguranjem, što obezbeđuje siguran
    transport robe od mesta utovara do mesta istovara.
    `,
      English: `Since its establishment, transport has been one of the main activities of the company. Our vehicle fleet is constantly being modernized and expanded, and currently it consists of <b>60 state-of-the-art SCANIA trucks</b> with EURO-5 and EURO-6 engines. All vehicles are equipped with GPS navigation and tracking technology. All our vehicles are covered by CMR insurance, which ensures safe transport of goods from the place of loading to the place of unloading.`,
    },
    boxes: [
      {
        title: {
          Srpski: "Transport",
          English: "Transportation",
        },
        description: {
          Srpski:
            "Na transport se od osnivanja obraća pažnja kao na jednu od glavnih delatnosti preduzeća.",
          English:
            "Since its establishment, transport has been one of the main activities of the company.",
        },
        pageDescription: {
          Srpski: `Sektor transporta i logistike vrši organizaciju prevoza pošiljki drumskim
        transportom. Težište poslovanja je na drumskom prevozu <b>širom Evrope</b> koji
        organizujemo vozilima iz sopstvenog voznog parka. <br /> <br />
        <b>Međunarodni drumski transport i lokalna distribucija po Srbiji</b> se odvija u
        kontinuitetu 7 dana, 24 časa. Potpuno smo fleksibilni za sve zahteve klijenta, sa
        aspekta vremena i mesta prijema i isporuke robe. Sva vozila su praćena GPS
        sistemom što omogućava potpunu vidljivost isporuke za klijenta u svakom
        momentu, odnosno praćenje kretanja robe u realnom vremenu.`,
          English: `The Transportation and Logistics Sector coordinates the transport of consignments by road. Our main business focus is on road transport throughout Europe, with vehicles from our own fleet. <br /> <br /> <b>International road transport and local distribution in Serbia</b> takes place continuously for 7 days, 24 hours a day.We are completely flexible for all customer requirements, from the aspect of time and place of receipt and delivery of goods.All vehicles are monitored by a GPS system, which enables full visibility of delivery for the client at any time, i.e.tracking the movement of goods in real time.`,
        },
        image: transport,
      },
      {
        title: {
          Srpski: "Špedicija",
          English: "Freight company",
        },
        description: {
          Srpski:
            "U cilju kompletiranja poslovne ponude u oblasti transporta robe, pružamo špediterske usluge zainteresovanim korisnicima.",
          English:
            "In order to complete the business offer in the field of goods transport, we provide freight forwarding services to interested customers.",
        },
        pageDescription: {
          Srpski: `U cilju kompletiranja poslovne ponude u oblasti transporta robe, pružamo
          <b>špediterske usluge zainteresovanim korisnicima</b>, kao smeštanje robe
          u carinski magacin. <br />
          Ova vrsta usluga, pored poslova organizovanja prevoza i preuzimanja robe,
          uključuje i poslove carinjenja iste kod nadležnih državnih organa, kao sve ostale
          prateće poslove u vezi sa transportom`,
          English: `In order to complete the business offer in the field of goods transport, we provide <b>freight forwarding services to interested customers</b>, such as placing goods in a customs warehouse. <br /> This type of services, in addition to the transport organization and collection of goods, includes customs clearance with state authorities, and other ancillary activities related to transport.`,
        },
        image: spedicija,
      },
      {
        title: {
          Srpski: "Carinski magazin",
          English: "Customs warehouse",
        },
        description: {
          Srpski:
            "U prostoru površine više od 600m<sup>2</sup> nudimo skladištenje robe kao i usluge carinskog magacina.",
          English:
            "In an area of more than 600m<sup>2</sup> we offer storage of goods and customs warehouse services.",
        },
        pageDescription: {
          Srpski: `U prostoru površine <b>više od 600m<sup>2</sup></b> nudimo skladištenje robe
        kao i usluge carinskog magacina.`,
          English: `In an area of <b>more than 600m<sup>2</sup></b> we offer storage of goods and customs warehouse services.`,
        },
        image: carinskimagazin,
      },
      {
        title: {
          Srpski: "Servis",
          English: "Service center",
        },
        description: {
          Srpski:
            "Uz Dugogodišnje iskustvo u transportu i razvoju transportnih usluga kroz povećanje broja vozila razvio se servis.",
          English:
            "With many years of experience in transport and through the constant increase of our vehicle fleet, we have opened a company's service center that maintains our fleet at the highest possible level.",
        },
        pageDescription: {
          Srpski: `Uz dugogodišnje iskustvo u transportu i razvoju transportnih usluga kroz povećanje
        voznog parka razvio se i naš servis. Tako sada posedujemo servis u sklopu firme koji
        naš vozni park održava na najvećem mogućem nivou koji se i zahteva da bi usluga
        transporta bila maksimalno efikasna, kvalitetna i izvršena uvek na vreme. <br /> <br />
        U okviru servisnog centra, trećim licima <b>nudimo sledeće usluge</b>:
        Mehaničarske usluge, autoelektričarske usluge, bravarske usluge, varilačke usluge,
        farbarske usluge, vulkanizerske usluge, centriranje trapa, metalostrugarske usluge,
        prodaju rezervnih delova, mobilni terenski servis.
        Od januara 2018. godine smo postali <b>ovlašćeni serviser Schwarzmuller prikolica</b>.`,
          English: `With many years of experience in transport and through the constant increase of our vehicle fleet, we have opened a company's service center that maintains our fleet at the highest possible level. The highest possible standards are set for the transport service to be maximally efficient, high quality and always on time, and our team of experienced mechanics are making sure we always follow those standards. <br /> <br /> Within the service center, <b>we offer the following services to third parties</b>: Mechanical services, car electric services, locksmith services, welding services, painting services, vulcanization services, centering of traps, metal turning services, sale of spare parts, mobile field service. From January 2018, we became an authorized service center of Schwarzmuller trailers.`,
        },
        image: servis,
      },
      {
        title: {
          Srpski: "Parking",
          English: "Parking lot",
        },
        description: {
          Srpski:
            "Dragicevic d.o.o. na sopstvenom parkingu kapaciteta 100 mesta nudi usluge pairkranja kamiona sa ili bez pratećih usluga servisa ili pranja.",
          English:
            "Dragicevic d.o.o. in its own parking lot with a capacity of 100 places, offers truck parking services with or without accompanying service of washing and repair.",
        },
        pageDescription: {
          Srpski: `Dragicevic d.o.o. na sopstvenom parkingu <b>kapaciteta 100 mesta</b>
        nudi usluge pairkranja kamiona sa ili bez pratećih usluga servisa ili pranja.`,
          English: `Dragicevic d.o.o. in its own parking lot with a <b>capacity of 100 places</b>, offers truck parking services with or without accompanying service of washing and repair.`,
        },
        image: parking,
      },
      {
        title: {
          Srpski: "Perionica",
          English: "Truck wash",
        },
        description: {
          Srpski:
            "Naša perionica pruža mogućnost podnog pranja, intenzivno predpranje, visokopritisno pranje spinerima (60 bara) i voskiranje.",
          English:
            "The innovative and high-performance <b>Kärcher</b> wash system is the state-of-the-art generation with  60 bar floor washing, intensive pre-washing, three-brush washing, high-pressure spinner washing (60 bar) and waxing.",
        },
        pageDescription: {
          Srpski: `U okviru servisa posedujemo i automatsku perionicu. Nova automatska perionica
        marke <b>Kärcher</b> je najsavremenije generacije koja pruža mogućnost podnog pranja
        pod pritiskom od 60 bara, intenzivno predpranje, pranje sa tri četke, visokopritisno
        pranje spinerima (60 bara) i voskiranje.`,
          English: `One of the services we offer is fully automated truck wash. The innovative and high-performance <b>Kärcher</b> wash system is the state-of-the-art generation with  60 bar floor washing, intensive pre-washing, three-brush washing, high-pressure spinner washing (60 bar) and waxing.`,
        },
        image: perionica,
      },
    ],
  },
  goals: {
    text: {
      Srpski:
        "Naš cilj je da vozila uvek održavamo na najvećem mogućem nivou koji se zahteva da bi usluga transporta bila maksimalno efikasna, kvalitetna i izvršena uvek na vreme.",
      English:
        "Our goal is to always maintain vehicles at the highest possible level, which is required for the transport service to be as efficient, high quality and always on time. ",
    },
    whiteText: {
      Srpski: "Pouzdan partner za ostvarenje vaših ciljeva",
      English: "A reliable partner to achieve your goals.",
    },
    image: goal,
  },
  partners: {
    img: partners,
    title: {
      Srpski: "Naši partneri",
      English: "Our partners",
    },
    logos: [
      scania,
      dhlc,
      smc,
      gwc,
      dbs,
      fercam,
      dunav,
      mol,
      millagrisrl,
      knc,
      cdg,
      ads,
    ],
  },
  aboutUs: {
    mainImg: main,
    lowerImg: aboutLower,
    stats: [
      {
        number: 28,
        text: {
          Srpski: "Godina iskustva",
          English: "Years of experience",
        },
      },
      {
        number: 60,
        text: {
          Srpski: "Vozila",
          English: "Vehicles",
        },
      },
      {
        number: 90,
        text: {
          Srpski: "Zaposlenih",
          English: "Employees",
        },
      },
      {
        number: 620,
        type: `m<sup>2</sup>`,
        text: {
          Srpski: "Carinski magacin",
          English: "Customs warehouse",
        },
      },
    ],
    icons: [
      {
        icon: truckGraphic,
        text: {
          Srpski: "Najsavremeniji kamioni",
          English: "State-of-the-art trucks",
        },
      },
      {
        icon: listGraphic,
        text: {
          Srpski: "Pouzdan partner",
          English: "A reliable partner",
        },
      },
      {
        icon: phoneGraphic,
        text: {
          Srpski: "Ekonomično poslovanje",
          English: "Economical business management",
        },
      },
    ],
  },
  gallery: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img13,
    img14,
    img15,
    img16,
  ],
};

export default data;
