import React, { useState, useEffect } from "react";
import "./index.scss";
import { NavLink, withRouter } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import Button from "../shared/Button";
import { setLanguage } from "../../actions/actions";

import location from "../../assets/images/icons/location.svg";
import phone from "../../assets/images/icons/phone.svg";
import logo from "../../assets/images/logos/logo.png";


import { connect } from "react-redux";


import cx from "classnames";
import animateScrollTo from 'animated-scroll-to';

const Header = ({ burgerActive, setBurgerActive, dispatch, language }) => {


  const [scrollPos, setScrollPos] = useState(0);
  const [direction, setDirection] = useState("");
  const [shouldHeaderAnimate, setShouldHeaderAnimate] = useState(false);

  const handleScroll = () => {
    const windowScroll = window.scrollY || window.pageYOffset;
    const isScrollingDown = windowScroll > scrollPos;
    setDirection(isScrollingDown ? "bottom" : "top");
    setScrollPos(windowScroll);
    if (windowScroll >= 200) {
      setShouldHeaderAnimate(true);
    } else {
      setShouldHeaderAnimate(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });


  const burgerClasses = cx("burger", burgerActive && "burger__active");

  const height = window.innerHeight;
  const hiddenHeader = direction === "bottom" && scrollPos > height * 1.5;
  const headerClasses = cx(
    "header",
    shouldHeaderAnimate && "header__animated",
    hiddenHeader && "header__hidden"
  );

  const home = {
    Srpski: "Početna",
    English: "Home"
  }

  const about = {
    Srpski: "O nama",
    English: "About us"
  }

  const services = {
    Srpski: "Usluge",
    English: "Services"
  }

  const gallery = {
    Srpski: "Galerija",
    English: "Gallery"
  }

  const contact = {
    Srpski: "Kontakt",
    English: "Contact"
  }

  const label = {
    Srpski: "Kontaktirajte nas",
    English: "Contact us"
  }

  return (
    <div className={headerClasses}>
      <div className="header-left">
        <NavLink exact to={routeCodes.HOME}>
          <img style={{ display: burgerActive ? 'none' : 'flex' }} src={logo} alt="logo" />
        </NavLink>
        <div className="header-left-navlinks">
          <NavLink
            exact
            activeClassName="header-link__active"
            className="header-link"
            to={routeCodes.HOME}
          >
            {home[language]}
          </NavLink>
          <NavLink
            exact
            activeClassName="header-link__active"
            className="header-link"
            to={routeCodes.ABOUT}
          >
            {about[language]}
          </NavLink>
          <NavLink
            exact
            activeClassName="header-link__active"
            className="header-link"
            to={routeCodes.SERVICES}
          >
            {services[language]}
          </NavLink>
          <NavLink
            exact
            activeClassName="header-link__active"
            className="header-link"
            to={routeCodes.GALLERY}
          >
            {gallery[language]}
          </NavLink>
          <NavLink
            exact
            activeClassName="header-link__active"
            className="header-link"
            to={routeCodes.CONTACT}
          >
            {contact[language]}
          </NavLink>
        </div>
      </div>
      <div className="header-right">
        <button
          className={burgerClasses}
          onClick={() => setBurgerActive(!burgerActive)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="header-right-phone">
          <img src={phone} alt="phone" />
          <p>+381 11 8701493</p>
        </div>
        <div className="header-right-location">
          <img src={location} alt="location" />
          <p>Železnička 42/D, Barič 11504, Srbija</p>
        </div>
        <div className="languages">
          <button onClick={() => dispatch(setLanguage("Srpski"))}>SR</button>
          <p>|</p>
          <button onClick={() => dispatch(setLanguage("English"))}>EN</button>
        </div>
        <div className="header-button-wrapper">
          <Button
            label={label}
            isWhite
            onClick={() => animateScrollTo(document.getElementById("footer"), 1000)} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default withRouter(connect(mapStateToProps)(Header));
